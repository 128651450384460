<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div
    style="margin-top: 0px;"
    @click="removeLists"
  >
    <b-tabs
      v-model="tabIndex"
    >
      <b-tab
        @click="goToClients()"
      >
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("Clients") }}</strong>
        </template>
        <div style="margin-top: 20px;">

          <div
            class="table__header "
            style="margin-bottom: 15px !important; justify-content: space-between;"
          >
            <div class="d-flex">
              <!-- <button
                v-b-modal.add-new-client
                class="button"
                style="border-radius: 6px; margin-right: 25px;background: #FF274F !important;
                color: #f4f1ed;
                border-color: transparent;"
              >
                <b-icon-plus />
                {{ $t('AddNewLead') }}
              </button> -->
              <div style="display:flex; padding-right: 15px;">
                <div>
                  <div class="black">
                    <b-icon-search
                      v-if="searchClients == null"
                      class="search1"
                      style=" position: absolute; left: 5px; top: 10px"
                    />
                    <b-icon-x-circle
                      v-else-if="searchClients != null && !noSearch"
                      class="search1"
                      style="position: absolute; margin-top: -6px; right: 10px; top: 17px; cursor: pointer"
                      @click="searchClients = null"
                    />
                    <input
                      v-model="searchClients"
                      type="text"
                      autocomplete="off"
                      name="search-box"
                      :placeholder="$t('ClientName')"
                      :style="getFirstNames.length > 0 && noSearch ? 'border-radius: 8px 8px 8px 8px' : 'border-radius: 8px 8px 8px 8px; padding-left: 25px;'"
                      @keyup="inputChanged"
                      @keydown.down="onArrowDown"
                      @keydown.up="onArrowUp"
                    >
                  </div>
                  <div
                    v-if="getFirstNames.length > 0 && noSearch"
                    ref="scrollContainer"
                    class="dropdrop"
                    :style="getFirstNames.length > 7 ? 'height: 210px' : 'height: auto'"
                  >
                    <div
                      v-for="(result, index) in getFirstNames"
                      :key="index"
                      ref="options"
                      style="cursor: pointer"
                      class="search-item"
                      :class="{ 'is-active': index === arrowCounter }"
                      @click="searchByOn(result); noSearch = false"
                    >
                      <p style="margin: 0">
                        {{ result.nameToDisplay }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              v-b-modal.potential-dress-for-client
              class="button"
              @click="productsInStock()"
            >
              {{ $t('Potential Dress') }}
            </button>
          </div>
          <div style="width: 100%;  padding-top: 16px; margin-top: 15px; height: 75vh; overflow-y: scroll">
            <table
              ref="exportable_table"
              class="team_table"
            >
              <thead style="position: sticky; top: -17px">
                <tr>
                  <th>
                    {{ $t('No') }}
                  </th>
                  <th>
                    {{ $t('Salutation') }}
                  </th>
                  <th>
                    {{ $t('CustomerName') }}
                  </th>
                  <th>
                    {{ $t('Gender') }}
                  </th>
                  <th>
                    {{ $t('DateofBirth') }}
                  </th>
                  <th>
                    {{ $t('Email') }}
                  </th>
                  <th>
                    {{ $t('PhoneNumber') }}
                  </th>
                  <th v-if="getLoggedInUser.role != 'HR' && getLoggedInUser.role != 'Accountant'">
                    {{ $t('Actions') }}
                  </th>
                  <th>
                    {{ $t('SendEmail') }}
                  </th>
                  <th
                    colspan="2"
                  >
                    {{ $t('ViewOrders') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(client, index) in getClients"
                  :key="client.clientId"
                  v-b-tooltip.hover.bottom="isOrdersTooltipVisible ? '' : undefined"
                  :title="getLanguage == 'en' ? `${$t('ClickFor') +' '+ client.firstName +' '+ client.lastName +' '+ $t('Details')}` : `${$t('ClickForDetails') +' '+ client.firstName +' '+client.lastName}`"
                  style="border-bottom: 1px solid #dcdfe6; color: #606266; text-align: left"
                  class="hover-row"
                  @click="viewDetails(client.clientId)"
                >
                  <td>
                    {{ getTotalItemsForClients - (page - 1) * pageSize - index }}.
                  </td>
                  <td>
                    {{ $t(client.salutation) }}
                  </td>
                  <td>
                    {{ client.firstName }} {{ client.lastName }}
                  </td>
                  <td>
                    {{ $t(client.gender) }}
                  </td>
                  <td>
                    {{ dateModified(client.dateOfBirth) }}
                  </td>
                  <td>
                    {{ client.email }}
                  </td>
                  <td>
                    +{{ countryCallingCode[client.countryCode] }} {{ client.telephoneNumber }}
                  </td>
                  <td
                    v-if="getLoggedInUser.role != 'HR' && getLoggedInUser.role != 'Accountant'"
                    v-b-toggle.clients-edit-modal
                    @click.stop="goToClientInfo(client.clientId)"
                  >
                    <p class="p-0 m-0 ml-50">
                      <b-icon-pencil />
                    </p>
                  </td>
                  <td>
                    <button
                      id="birthday"
                      v-b-modal.client-email-modal
                      class="editButton"
                      type="button"
                      variant="none"

                      @click.stop="sendToModal(client)"
                    >
                      {{ $t('Email') }}

                    </button>
                  </td>
                  <td
                    @click.stop="goToClientWork(client.clientId)"
                  >
                    <button
                      v-b-toggle.client-orders-view
                      v-b-tooltip.hover.bottom
                      class="editButton"
                      type="button"
                      variant="none"
                      :title="$t('ClickViewOrders')"
                      @mouseenter="showOrdersTooltip"
                      @mouseleave="hideOrdersTooltip"
                    >
                      {{ $t('Orders') }}

                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <ClientOrdersModal
              :client-id="clientId"
              @onCancelToggle="onCancelToggle"
            />
          </div>
          <div
            v-if="tabIndex == 0"
          >
            <b-pagination
              v-if="getTotalItemsForClients > 15"
              v-model="page"
              :total-rows="getTotalItemsForClients"
              :per-page="pageSize"
              first-number
              last-number
              align="fill"
              prev-class="prev-itemi"
              next-class="next-itemi"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
              </template>
              <template #next-text>
                <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
              </template>
            </b-pagination>
          </div>
        </div>
      </b-tab>
      <b-tab
        id="leads"
        @click="goToLeads()"
      >
        <template #title>
          <strong style="font-weight: 500;color: black">{{ $t("Leads") }}</strong>
        </template>
        <div style="margin-top: 20px; ">
          <div style="width: 100%; ">
            <div class="d-flex">
              <button
                v-b-modal.add-new-client
                class="button"
                style="margin-right: 25px; width: 13%"
              >
                {{ $t('AddNewLead') }}
              </button>
              <button
                v-b-modal.add-campaign
                class="button"
                style="width: 13%"
              >
                {{ $t('SendCampaign') }}
              </button>
            </div>
            <div
              style="width: 100%; border-top: 1px solid rgb(210, 209, 209); padding-top: 16px; margin-top: 15px; height: 75vh; overflow-y: scroll;"
            >
              <table
                ref="exportable_table"
                class="team_table"
              >
                <thead style="position: sticky; top: -17px">
                  <tr>
                    <th>
                      <input
                        v-model="isAllChecked"
                        type="checkbox"
                        @click="toggleAllCheckboxes"
                      >
                    </th>
                    <th>
                      {{ $t('No') }}
                    </th>
                    <th>
                      {{ $t('Salutation') }}
                    </th>
                    <th>
                      {{ $t('Leads') }}
                    </th>
                    <th>
                      {{ $t('LeadOwner') }}
                    </th>
                    <th>
                      {{ $t('Gender') }}
                    </th>
                    <th>
                      {{ $t('DateofBirth') }}
                    </th>
                    <th>
                      {{ $t('Email') }}
                    </th>
                    <th>
                      {{ $t('PhoneNumber') }}
                    </th>
                    <th v-if="getLoggedInUser.role != 'HR' && getLoggedInUser.role != 'Accountant'">
                      {{ $t('Actions') }}
                    </th>
                    <th>
                      {{ $t('LeadHistory') }}
                    </th>
                  <!-- <th
                    colspan="2"
                    style="padding: 10px; border-top-left-radius: 0px; color:#262E6C !important; ; border-top-right-radius: 10px; text-align:left;font-size: 15px;"
                  >
                    {{ $t('Orders') }}
                  </th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(client, index) in getClients"
                    :key="client.clientId"
                    v-b-tooltip.hover.bottom
                    :title="getLanguage == 'en' ? `${$t('ClickFor') +' '+ client.firstName +' '+ client.lastName +' '+ $t('Details')}` : `${$t('ClickForDetails') +' '+ client.firstName +' '+client.lastName}`"
                    class="hover-row"
                    @click="viewDetails(client.clientId)"
                  >
                    <td>
                      <input
                        v-model="selectedClients"
                        type="checkbox"
                        :value="client"
                        @click.stop="singleCheckbox(client)"
                      >
                    </td>
                    <td>
                      {{ getTotalItemsForClients - (page2 - 1) * pageSize2 - index }}.
                    </td>
                    <td>
                      {{ $t(client.salutation) }}
                    </td>
                    <td>
                      {{ client.firstName }} {{ client.lastName }}
                    </td>
                    <td
                      v-if="client.ownerUserId == null"
                      v-b-modal.add-lead-modal
                      @click.stop="addLeadOwner(client)"
                    >
                      <b-button
                        class="buttonSubmit"
                      >
                        {{ $t("Assign") }}
                      </b-button>
                    </td>
                    <td
                      v-else
                      v-b-modal.add-lead-modal
                      @click.stop="addLeadOwner(client)"
                    >
                      <b-button
                        class="buttonSubmit"
                      >
                        {{ getUserFirstName(client.ownerUserId) }}
                      </b-button>
                    </td>
                    <td>
                      {{ $t(client.gender) }}
                    </td>
                    <td>
                      {{ dateModified(client.dateOfBirth) }}
                    </td>
                    <td>
                      {{ client.email }}
                    </td>
                    <td>
                      +{{ countryCallingCode[client.countryCode] }} {{ client.telephoneNumber }}
                    </td>
                    <td
                      v-if="getLoggedInUser.role != 'HR' && getLoggedInUser.role != 'Accountant'"
                      v-b-toggle.clients-edit-modal
                      style="cursor: pointer"
                      @click.stop="goToClientInfo(client.clientId)"
                    >
                      <p class="p-0 m-0 ml-50">
                        <b-icon-pencil />
                      </p>
                    </td>
                    <td
                      v-b-toggle.sidebar-right
                      @click.stop="characteristics(client)"
                    >
                      <p>
                        <b-icon-arrow-right
                          class="bigger-icon"
                          color="black"
                        />
                      </p>
                    </td>
                  <!-- <td
                    style="padding: 9px;; font-weight: 400; color: black; text-align: left; cursor: pointer"
                    @click.stop="goToClientWork(client.clientId)"
                  >
                    <p class="p-0 m-0 ml-50">
                      <b-icon-arrow-right
                        class="bigger-icon"
                        color="black"
                      />
                    </p>
                  </td> -->
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
          <div
            v-if="tabIndex == 1"
          >
            <b-pagination
              v-if="getTotalItemsForClients > 20"
              v-model="page2"
              :total-rows="getTotalItemsForClients"
              :per-page="pageSize2"
              first-number
              last-number
              align="fill"
              prev-class="prev-itemi"
              next-class="next-itemi"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
              </template>
              <template #next-text>
                <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
              </template>
            </b-pagination>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <div
      v-if="isSidebarOpen"
      class="backdrop"
    />
    <AddClientModalVue @click="addClients" />
    <AddLeadOwner @leadi="leadOwner" />
    <ClientEditModal
      :id="clientId"
      @onCancelToggle="onCancelToggle"
      @updateClient="updateClient"
    />
    <ViewClientDetails
      :client="client"
      @click="addClients"
    />
    <PotentialDressForClient />
    <LeadHistory
      :client-id="clientId"
      :lead-client="leadClient"
      @onCancelToggle="onCancelToggle"
    />
    <ClientsEmailOcassion
      :send-to-modal-object="sendToModalObject"
      @sendEmail="sendEmail"
    />
    <AddCampaign
      :is-all-checked="isAllChecked"
      :clients="selectAllClients"
    />
  </div>

</template>

<script>
// import { mapActions, mapGetters } from 'vuex'
// import moment from 'moment'

import { mapActions, mapGetters, mapState } from 'vuex';
import AddClientModalVue from '@/modals/AddClientModal.vue';
import ViewClientDetails from '@/modals/ViewClientDetails.vue'
import { countryCallingCodes } from '@/countryCodes'
import PotentialDressForClient from '@/components/clients/modals/PotentialDressForClientModal.vue'
import moment from 'moment'
import ClientEditModal from '@/components/clients/modals/ClientEditModal.vue'
import ClientOrdersModal from '@/components/clients/modals/ClientOrdersModal.vue'
import ClientsEmailOcassion from '@/components/clients/modals/ClientsEmailOcassion.vue'
import AddLeadOwner from '@/components/clients/modals/AddLeadOwner.vue'
import { client } from '@/domainConfig'
import AddCampaign from '@/components/clients/modals/AddCampaign.vue'
import LeadHistory from '../LeadHistory.vue';

// const PNF = require('google-libphonenumber').PhoneNumberFormat;
// const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export default {
  components: {
    AddClientModalVue,
    ViewClientDetails,
    PotentialDressForClient,
    LeadHistory,
    ClientEditModal,
    ClientOrdersModal,
    ClientsEmailOcassion,
    AddCampaign,
    AddLeadOwner,
  },
  data() {
    return {
      isOrdersTooltipVisible: false,
      isSidebarOpen: false,
      allUsersPagination: [],
      allClients: [],
      selectedClients: [],
      selectedClientIds: null,
      clientLogo: client.clientLogo,
      clientName: client.clientName,
      sendToModalObject: null,
      tabIndex: 0,
      page: 1,
      pageSize: 15,
      page2: 1,
      pageSize2: 20,
      totalItems: '',
      searchClients: null,
      client: {},
      countryCallingCode: countryCallingCodes,
      noSearch: false,
      status: true,
      arrowCounter: 0,
      enterPressed: false,
      clientId: '',
      isAllChecked: false,
      leadClient: null,
    };
  },
  computed: {
    ...mapGetters([
      'getLanguage',
      'getClient',
      'getTotalItemsForClients',
      'getFirstNames',
      'getLoggedInUser',
      'getIsLoading',
      'getClients',
      'getTotalPagesForClients',
      'getUserRole',
      'getFullUsersById',
      'getUsers',
      'getUsersByRole',
      'getTotalPagesUsers',
      'getCharacteristicsByClientId',
    ]),
    allOwners() {
      const allLeadOwners = this.getClients.map((itemi) => { return { userId: itemi.ownerUserId } })
      const filterNullOwners = allLeadOwners.filter((itemi) => { return itemi.userId !== null })
      return filterNullOwners
    },
    allUsers() {
      return this.getUsersByRole.map((itemi) => { return { userId: itemi.userID, fullName: itemi.fullName } })
    },
    clientsAll() {
      return this.allClients;
    },
    allClientsByPagination() {
      return this.allUsersPagination.map((itemi) => { return { userId: itemi.userId, firstName: itemi.firstName } })
    },
    selectAllClients() {
      let clients
      if (this.isAllChecked == true) {
        clients = this.allClients
      } else {
        clients = this.selectedClients
      }
      return clients
    },
    formattedPhoneNumber() {
      const countryCode = this.client.countryCode;
      const callingCode = this.countryCallingCode[countryCode];
      const phoneNumber = this.client.telephoneNumber;
      return `+${callingCode} ${phoneNumber}`;
    },
  },
  watch: {
    page(value) {
      if (this.tabIndex == 0) {
        this.getClientsPagination({
          clientId: this.searchClients,
          isLead: false,
          pageNumber: value,
          pageSize: this.pageSize,
          clientName: null,
        });
      }
    },
    page2(value) {
      if (this.tabIndex == 1) {
        this.getClientsPagination({
          clientId: this.searchClients,
          isLead: true,
          pageNumber: value,
          pageSize: this.pageSize2,
          clientName: null,
        });
      }
    },
    searchClients(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == null || value == '') {
          this.removeLists()
          this.getClientsPagination({
            clientId: null,
            isLead: false,
            pageNumber: this.page,
            pageSize: this.pageSize,
          });
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.firstNames(value);
          this.noSearch = true
        }
      }, 500)
    },
  },
  mounted() {
    this.fetch()
    this.usersByRole({
      personRole: 'SalesPerson',
    })
    // this.getUsersPagination({
    //   pageNumber: this.page,
    //   pageSize: 15,
    // })
  },
  methods: {
    ...mapState(['clients']),
    ...mapActions([
      'addClient',
      'getOrdersOverview',
      'getClientsPagination',
      'changeLoadingtoTrue',
      'getClientById',
      'firstNames',
      'resetSearchClients',
      'resetCLients',
      'sendEmailToUser',
      'loadProductInStock',
      'resetCLientsCompare',
      'editClient',
      'sendEmailToClientForDress',
      'getUsersRole',
      'addRoleToLead',
      'usersByRole',
      'fullUserById',
      'getUsersPagination',
      'characteristicsByClientId',
      'eventsReporting',
    ]),
    showOrdersTooltip() {
      this.isOrdersTooltipVisible = true;
    },
    hideOrdersTooltip() {
      this.isOrdersTooltipVisible = false;
    },
    onCancelToggle() {
      this.isSidebarOpen = false
    },
    characteristics(value) {
      this.isSidebarOpen = true
      this.clientId = value.clientId
      this.leadClient = value.ownerUserId
      this.characteristicsByClientId({
        clientName: null,
        clientId: value.clientId,
      })
      this.eventsReporting({
        clientName: null,
        clientId: value.clientId,
        pageNumber: 1,
        pageSize: 4,
      })
    },
    getUserFirstName(ownerUserId) {
      const user = this.allUsers.find((u) => { return u.userId === ownerUserId });

      // Check if the user is found
      if (user) {
        const firstName = user.fullName;
        console.log('firstName', firstName);
        return firstName;
      }
      console.log('User not found');
      return null;
    },
    leadOwner(obj) {
      console.log('objekti', obj)
      this.addRoleToLead({
        clientId: this.clientId,
        ownerUserId: obj,
        successCallback: () => {
          this.getClientsPagination({
            pageNumber: this.page2,
            pageSize: this.pageSize,
            isLead: true,
          });
          this.fullUserById(obj)
        },
      })
      this.clientId = null
    },
    addLeadOwner(value) {
      this.clientId = value.clientId
      this.leadClient = value.ownerUserId
      console.log('value', value)
      this.usersByRole({
        personRole: 'SalesPerson',
      })
    },
    async fetchAllClients() {
      this.allClients = [];
      const promises = [];
      promises.push(this.getClientsOnPage(1));

      const totalPages = this.getTotalPagesForClients;

      for (let pageNumber = 2; pageNumber <= totalPages; pageNumber++) {
        promises.push(this.getClientsOnPage(pageNumber));
      }
      await Promise.all(promises);
    },
    async getClientsOnPage(pageNumber) {
      await this.getClientsPagination({
        clientId: null,
        isLead: true,
        pageNumber,
        pageSize: this.pageSize2,
        clientName: null,
      });
      this.allClients = [...this.allClients, ...this.getClients];
    },
    includeClients(pageNumber) {
      return this.getClientsPagination({
        pageNumber,
        pageSize: this.pageSize2,
        isLead: true,
      })
        .then(() => {
          return this.generateClients(this.getClients);
        })
        .catch((error) => {
          console.error('Error downloading the data:', error);
        });
    },
    generateClients(items) {
      const body = items.map((item) => {
        return [
          item.clientId,
          item.email,
          item.lastName,
          item.firstName,
        ]
      });
      console.log('items', items)
      return { body };
    },
    // Methods for fetching all users

    // async fetchAllUsers() {
    //   this.allUsersPagination = [];
    //   const promises = [];
    //   promises.push(this.getUsersOnPage(1));

    //   const totalPages = this.getTotalPagesUsers;

    //   for (let pageNumber = 2; pageNumber <= totalPages; pageNumber++) {
    //     promises.push(this.getUsersOnPage(pageNumber));
    //   }
    //   await Promise.all(promises);
    // },
    // async getUsersOnPage(pageNumber) {
    //   await this.getUsersPagination({
    //     pageNumber,
    //     pageSize: 15,
    //   });
    //   this.allUsersPagination = [...this.allUsersPagination, ...this.getUsers];
    // },
    // includeUsers(pageNumber) {
    //   return this.getUsersPagination({
    //     pageNumber,
    //     pageSize: 15,
    //   })
    //     .then(() => {
    //       return this.generateUsers(this.getUsers);
    //     })
    //     .catch((error) => {
    //       console.error('Error downloading the data:', error);
    //     });
    // },
    // generateUsers(items) {
    //   const body = items.map((item) => {
    //     return [
    //       item.email,
    //       item.lastName,
    //       item.firstName,
    //     ]
    //   });
    //   console.log('items', items)
    //   return { body };
    // },
    sendToModal(value) {
      this.sendToModalObject = value
    },
    sendEmail(value) {
      const FullName = `${this.sendToModalObject.firstName} ${this.sendToModalObject.lastName}`;
      console.log('imella', value)
      this.sendEmailToClientForDress({
        object: {
          email: this.sendToModalObject.email,
          discount: value.discount,
          fullName: FullName,
          ocassion: value.ocassion,
          iconUrl: this.clientLogo,
          files: value.files,
          dressUrl: null,
          // iconUrl: 'https://spim-file.s3.amazonaws.com/DrenushaLogoWhite.png',
        },
      })
    },
    dateModified(xdate) {
      if (xdate != null) {
        return moment(xdate).format('DD/MM/YYYY');
      }
      return ''
    },
    // nameModified(value) {
    //   this.fullUserById(value)
    // },
    updateClient(value1) {
      this.editClient({
        clientId: this.clientId,
        object: value1,
        successCallback: () => {
          if (this.tabIndex == 1) {
            this.getClientsPagination({
              pageNumber: this.page,
              pageSize: this.pageSize,
              isLead: true,
            });
          } else if (this.tabIndex == 0) {
            this.getClientsPagination({
              pageNumber: this.page,
              pageSize: this.pageSize,
              isLead: false,
            });
          }
        },
      })
    },
    productsInStock() {
      this.resetCLientsCompare()
      this.loadProductInStock({
        assignedTeamNameId: null,
        articleNumber: null,
        state: 'InStock',
        pageNumber: 1,
        pageSize: 15,
      })
    },
    goToClients() {
      this.searchClients = null
      this.noSearch = false
      this.resetCLients();
      this.getClientsPagination({
        clientId: null,
        isLead: false,
        pageNumber: this.page,
        pageSize: this.pageSize,
        clientName: null,
      })
    },
    goToLeads() {
      this.searchClients = null
      this.resetCLients();
      this.getClientsPagination({
        clientId: null,
        isLead: true,
        pageNumber: this.page2,
        pageSize: this.pageSize2,
        clientName: null,
      })
    },
    async fetch() {
      if (this.tabIndex == 0) {
        await this.getClientsPagination({
          clientId: this.searchClients,
          isLead: false,
          pageNumber: this.page,
          pageSize: this.pageSize,
          clientName: null,
        })
      } else if (this.tabIndex == 1) {
        await this.getClientsPagination({
          clientId: this.searchClients,
          isLead: true,
          pageNumber: this.page2,
          pageSize: this.pageSize2,
          clientName: null,
        })
      }
    },
    async searchByOn(value) {
      console.log(value)
      this.searchClients = value.nameToDisplay
      this.noSearch = false
      if (this.tabIndex == 0) {
        await this.getClientsPagination({
          clientId: value.clientId,
          isLead: false,
          pageNumber: this.page,
          pageSize: this.pageSize,
          clientName: null,
        })
      } else if (this.tabIndex == 1) {
        await this.getClientsPagination({
          clientId: value.clientId,
          isLead: true,
          pageNumber: this.page2,
          pageSize: this.pageSize2,
          clientName: null,
        })
      }
    },
    removeLists() {
      this.noSearch = false;
      this.resetSearchClients();
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFirstNames.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredActiveUsers = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getFirstNames[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },

    async addClients(obj) {
      await this.addClient({
        object: obj,
        successCallback: () => {
          this.getClientsPagination({
            pageNumber: 1,
            pageSize: this.pageSize,
            isLead: true,
          });
          this.tabIndex = 1
        },
      });
    },
    goToClientInfo(item) {
      this.isSidebarOpen = true
      this.clientId = item
      console.log('id', item)
      this.getClientById({
        clientId: item,
        successCallback: () => {
        },
      });

      // this.$router.push(`/clients/${item.clientId}`, { props: { myProps: item } })
      // this.$router.push({
      //   name: 'edit-client',
      //   params: { id: item },
      // })
    },
    async goToClientWork(item) {
      this.isSidebarOpen = true
      this.getOrdersOverview({
        salesType: 'Product',
        pageNumber: 1,
        pageSize: this.pageSize,
        orderNumber: this.searchON,
        clientId: item,
        year: moment().format('YYYY'),
        pickUpDate: this.filterDate == '' ? null : this.filterDate,
        orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
        orderItemState: this.selectedOrderState == null ? null : this.selectedOrderState,
      });
      // this.$router.push(`/clients/${item.clientId}`, { props: { myProps: item } })
      this.clientId = item;
      // this.$router.push({
      //   name: 'client-orders',
      //   params: { id: item },
      // })
    },
    async viewDetails(item) {
      // this.changeLoadingtoTrue(false)

      const clienti = await this.getClientById({
        clientId: item,
        successCallback: () => {
          this.$bvModal.show('view-client');
          // this.changeLoadingtoTrue(true)
        },
      });
      this.client = clienti
    },
    toggleAllCheckboxes() {
      this.isAllChecked = !this.isAllChecked;

      if (this.isAllChecked) {
        this.fetchAllClients().then(() => {
          this.selectedClients = [...this.allClients];
        });
      } else {
        this.selectedClients = [];
      }
    },

    singleCheckbox(item) {
      const index = this.selectedClients.findIndex((selectedClient) => { return selectedClient.clientId === item.clientId });

      if (index !== -1) {
        this.selectedClients.splice(index, 1);
      } else {
        this.selectedClients.push(item);
      }
      this.isAllChecked = this.selectedClients.length === this.allClients.length;
    },


    // singleCheckbox(item) {
    //   const index = this.selectAllClients.indexOf(item);

    //   if (index !== -1) {
    //     this.isAllChecked = false;
    //   } else {
    //     this.selectedClients.push(item);
    //     if (this.selectedClients.length === this.getClients.length) {
    //       this.isAllChecked = true;
    //     }
    //   }
    // },

  },
}
</script>

<style scoped lang="scss">

.editButtoni {
  background: #FF274F;
  width: 120px !important;
  height: 35px;
  color: white;
  border: none;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
}

.editButtoni:hover {
  border: 2px solid #FF274F;
  background: white;
  color: #FF274F;
  transition: ease-in-out 0.3s;
  height: 35px;
  width: 120px;
}

.buttonSubmit {
  width: 80%;
  padding: 2px;
}

.team_table td {
  padding: 11px 9px !important;
}

.team_table {
  width: 100%;
  font-size: 12px;
  text-align: left;
}
.hover-row:hover {
    background-color: rgba(255, 39, 79, 0.1);
}
.search1 {
    padding: 0px;
  }
.black input {
  height: 40px;
}
.dropdrop::-webkit-scrollbar {
display: none;

}
.dropdrop {
display: inline-block;
overflow: auto;
position: absolute;
background: white;
width: 227px;
box-shadow: none;
z-index: 1;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: bold;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
table:hover {
  cursor: pointer;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
.is-active {
  background-color: #dedede;
}
.bigger-icon {
  font-size: 24px;
}

@media screen and (max-width: 1200px) {
  .buttonSubmit {
    width: 100%;
  }
}
</style>
